<template>
  <div>
    <div class="navbar-custom">
      <div class="container-fluid">
        <ul class="list-unstyled topnav-menu float-right mb-0">
          <li class="dropdown notification-list topbar-dropdown">
            <a class="nav-link dropdown-toggle waves-effect waves-light" @click="showintro()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-help-circle icon-dual"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <line x1="12" y1="17" x2="12.01" y2="17" />
              </svg>
            </a>
          </li>
          <li class="dropdown notification-list topbar-dropdown">
            <a
              class="nav-link dropdown-toggle waves-effect waves-light"
              data-toggle="dropdown"
              href="#"
              @click="gonotification()"
              role="button"
              aria-haspopup="false"
              aria-expanded="true"
            >
              <i class="fe-bell noti-icon"></i>
              <span
                class="badge badge-danger rounded-circle noti-icon-badge"
                v-if="ncount>0"
              >{{ncount}}</span>
            </a>
          </li>
          <li class="dropdown d-none d-lg-inline-block topbar-dropdown">
            <a
              class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
              data-toggle="dropdown"
              href="javascript:void(0)"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                :src="cur_lan=='en' ?'../assets/images/flags/us.jpg':'../assets/images/flags/japan.jpg'"
                alt="user-image"
                height="16"
              />
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item"></a>
              <a href="javascript:void(0);" @click="change_language('en')">
                <img src="../assets/images/flags/us.jpg" alt="user-image" class="mr-1" height="12" />
                <span class="align-middle" style="line-height: 200%;">English</span>
              </a>

              <a href="javascript:void(0);" class="dropdown-item"></a>
              <a href="javascript:void(0);" @click="change_language('jp')">
                <img
                  src="../assets/images/flags/japan.jpg"
                  alt="user-image"
                  class="mr-1"
                  height="12"
                />
                <span class="align-middle" style="line-height: 200%;">日本語</span>
              </a>
            </div>
          </li>

          <li class="dropdown notification-list topbar-dropdown">
            <a
              class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
              data-toggle="dropdown"
              href="javascript:void(0)"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img src="../assets/images/users/user-1.jpg" alt="user-image" class="rounded-circle" />
              <span class="pro-user-name ml-1">
                <label id="name">{{username}}</label>
                <i class="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-dropdown">
              <!-- item-->
              <div class="dropdown-header noti-title">
                <h6 class="text-overflow m-0">
                  <div class="lang" v-html="lan.welcome"></div>!
                </h6>
              </div>
              <!-- item-->
              <!-- <a href="./user-info.php" class="dropdown-item notify-item">
                        <i class="fe-user"></i>
                        <span>My Account</span>
                    </a>    
                    <div class="dropdown-divider">
              </div>-->
              <!-- item-->
              <a
                href="javascript:void(0);"
                class="dropdown-item notify-item"
                @click="goto('/custom_needs')"
              >
                <span>
                  <div class="lang" v-html="lan.needs"></div>
                </span>
              </a>
              <a href="javascript:void(0);" class="dropdown-item notify-item" @click="logout()">
                <i class="fe-log-out"></i>
                <span>
                  <div class="lang" v-html="lan.logout"></div>
                </span>
              </a>
            </div>
          </li>
        </ul>

        <!-- LOGO -->
        <div class="logo-box">
          <a href="/" class="logo logo-dark text-center">
            <span class="logo-sm">
              <img src="../assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="../assets/images/logo-dark.png" alt height="20" />
            </span>
          </a>

          <a href="/" class="logo logo-light text-center">
            <span class="logo-sm">
              <!-- <img src="../assets/images/logo-sm.png" alt height="22" /> -->
              <!-- <img src="../assets/images/logo-light.png" alt height="22" /> -->
            </span>
            <span class="logo-lg">
              <!-- <img src="../assets/images/logo-light.png" alt height="20" /> -->
            </span>
          </a>
        </div>
        <!-- <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button class="button-menu-mobile waves-effect waves-light" @click="show_menus">
              <i class="fe-menu"></i>
            </button>
          </li>
        </ul> -->

        <transition name="fade">
          <div v-show="isPopup" class="modal popup-modal">
            <div class="modal-dialog">
              <div class="popup modal-content help-box">
                <div class="modal-header">
                  <button type="button" class="close" @click="isPopup = false">
                    <span>×</span>
                  </button>
                </div>
                <div class="modal-body" v-if="isPopup">
                  <div class="area-btn">
                    <div v-html="current_description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="popup-bg" @click="isPopup = false"></div>
          </div>
        </transition>

        <div class="clearfix"></div>
      </div>
    </div>
    <!-- <div class="left-side-menu">
      <div class="h-100" data-simplebar="init">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div class="simplebar-content-wrapper" style="height: 100%; overflow: hidden;">
                <div class="simplebar-content" style="padding: 0px;">
                  
                  <div id="sidebar-menu">
                    <ul id="side-menu">
                      <li class="research">
                        <a
                          href="#sidebarDashboards"
                          data-toggle="collapse"
                          aria-expanded="true"
                          class="collapsed"
                        >
                          <i class="fe-clipboard"></i>
                          <span>{{lan.company_rent}}</span>
                        </a>
                        <div class="collapse show" id="sidebarDashboards" style>
                          <ul class="nav-second-level">
                            <li>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/corp_list_group?title='+lan.firms_tend_to_enhance+'&type=knx')"
                              >{{lan.company_rent_total}}</a>
                            </li>
                            <li class>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/corp_list_group?title='+lan.firms_tend_to_enhance+'&type=kz')"
                              >{{lan.firms_tend_to_enhance}}</a>
                            </li>
                            <li class>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/corp_list_group?title='+lan.firms_tend_to_enhance+'&type=jy')"
                              >{{lan.firms_likely_to_be_consolidated}}</a>
                            </li>
                            <li class>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/corp_list_group?title='+lan.firms_tend_to_enhance+'&type=sx')"
                              >{{lan.firms_tend_to_downsize}}</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="research">
                        <a href="javascript:void(0)" class="collapsed">
                          <i class="fe-clipboard"></i>
                          <span>{{lan.lease_rent_update}}</span>
                        </a>
                      </li>
                      <li class="research">
                        <a
                          href="#sidebarDashboards2"
                          data-toggle="collapse"
                          aria-expanded="true"
                          class="collapsed"
                        >
                          <i class="fe-search"></i>
                          <span>{{lan.search}}</span>
                        </a>
                        <div class="collapse show" id="sidebarDashboards2" style>
                          <ul class="nav-second-level">
                            <li>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/?type=company')"
                              >{{lan.company}}{{lan.search}}</a>
                            </li>
                            <li class>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/?type=address')"
                              >{{lan.address}}{{lan.search}}</a>
                            </li>
                            <li class>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/?type=building')"
                              >{{lan.building}}{{lan.search}}</a>
                            </li>
                            <li class>
                              <a href="javascript:void(0)">
                                {{lan.search_space}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="research">
                        <a
                          href="#sidebarDashboards3"
                          data-toggle="collapse"
                          aria-expanded="true"
                          class="collapsed"
                        >
                          <i class="fe-award"></i>

                          <span>{{lan.building_recommend}}</span>
                        </a>
                        <div class="collapse show" id="sidebarDashboards3" style>
                          <ul class="nav-second-level">
                            <li>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/report/add')"
                              >{{lan.building_recommend_add}}</a>
                            </li>
                            <li class>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/report')"
                              >{{lan.building_recommend_company}}</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="research">
                        <a
                          href="#sidebarDashboards4"
                          data-toggle="collapse"
                          aria-expanded="false"
                          class="collapsed"
                        >
                          <i class="fe-award"></i>
                          <span>{{lan.office_recommend}}</span>
                          <span class="badge badge-success badge-pill float-right">{{lan.todo}}</span>
                        </a>
                        <div class="collapse" id="sidebarDashboards4" style>
                          <ul class="nav-second-level">
                            <li>
                              <a href="javascript:void(0)">
                                {{lan.customer_info_input}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                            <li class>
                              <a href="javascript:void(0)">
                                {{lan.customer_need_input}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">
                                {{lan.office_select}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                            <li class>
                              <a href="javascript:void(0)">
                                {{lan.office_confirm}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="research">
                        <a
                          href="#sidebarDashboards5"
                          data-toggle="collapse"
                          aria-expanded="false"
                          class="collapsed"
                        >
                          <i class="fe-book"></i>
                          <span class="badge badge-success badge-pill float-right">{{lan.doing}}</span>
                          <span>{{lan.history_leases}}</span>
                        </a>
                        <div class="collapse" id="sidebarDashboards5" style>
                          <ul class="nav-second-level">
                            <li>
                              <a
                                href="javascript:void(0)"
                                @click="goto('/movingCases')"
                              >{{lan.history_leases_add}}</a>
                            </li>
                            <li class>
                              <a href="javascript:void(0)" @click="goto('/movingCasesList')">
                                {{lan.history_leases_search}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="research">
                        <a
                          href="#sidebarDashboards6"
                          data-toggle="collapse"
                          aria-expanded="false"
                          class="collapsed"
                        >
                          <i class="fe-users"></i>
                          <span class="badge badge-success badge-pill float-right">{{lan.todo}}</span>
                          <span>{{lan.predict}}</span>
                        </a>
                        <div class="collapse" id="sidebarDashboards6" style>
                          <ul class="nav-second-level">
                            <li class>
                              <a href="javascript:void(0)">
                                {{lan.season_tournament}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                            <li class>
                              <a href="javascript:void(0)">
                                {{lan.loto}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                            <li class>
                              <a href="javascript:void(0)">
                                {{lan.predict_result}}
                                <span
                                  class="badge badge-success badge-pill float-right"
                                >{{lan.todo}}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="research">
                        <a href="/#/market_info" class="collapsed">
                          <i class="fe-bar-chart-line-"></i>
                          <span>{{lan.market_data}}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                 
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: auto; height: 394px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div
            class="simplebar-scrollbar"
            style="width: 0px; display: none; transform: translate3d(0px, 0px, 0px);"
          ></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: hidden;">
          <div
            class="simplebar-scrollbar simplebar-visible"
            style="height: 0px; transform: translate3d(0px, 0px, 0px); display: none;"
          ></div>
        </div>
      </div>
      
    </div> -->
  </div>
</template>

<script>
// import { check_login } from "../components/common";
import router from "@/router";
import axios from "axios";
import { inject } from "vue";

export default {
  name: "Headers",
  props: {
    viewParams: {
      cur_menu: {
        type: String,
        require: true,
        default: "",
      },
    },
  },
  data() {
    return {
      menu_data: [],
      cur_lan: localStorage.getItem("cur_lan") != "en" ? "jp" : "en",
      email: "",
      ncount: 0,
      username: "",
      isPopup: 0,
      show_menu:
        localStorage.getItem("show_menu") != null
          ? localStorage.getItem("show_menu")
          : 1,
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
    };
  },
  mounted() {
    // check_login();
    this.username = localStorage.getItem("name");
    this.email = localStorage.getItem("email");
    this.show_menu = 0;
    if (this.show_menu == 0) {
      console.log(0);
      document.getElementsByTagName("body")[0].attributes[0].value =
        "condensed";
      document.getElementById("wrapper").style.paddingLeft = "60px";
    } else {
      console.log(1);
      document.getElementsByTagName("body")[0].attributes[0].value = "default";
      document.getElementById("wrapper").style.paddingLeft = "250px";
    }
    this.getMysetting();
  },
  methods: {
    show_menus() {
      this.show_menu = !this.show_menu;
      if (this.show_menu == 0) {
        document.getElementsByTagName("body")[0].attributes[0].value =
          "condensed";
        document.getElementById("wrapper").style.paddingLeft = "60px";
      } else {
        document.getElementsByTagName("body")[0].attributes[0].value =
          "default";
        document.getElementById("wrapper").style.paddingLeft = "250px";
      }
      localStorage.setItem("show_menu", this.show_menu ? 1 : 0);
    },
    getMysetting() {
      axios
        .get("/v1/getCbreaiSetting", {
          params: {
            email: localStorage.getItem("email"),
            id_token: localStorage.getItem("id_token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status == 1) {
            this.ncount = response.data.data.cnum;
          }
        })
        .catch((error) => console.log(error));
    },

    gonotification() {
      var date = new Date();
      var dd =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 > 9 ? "" : "0") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() + 1 > 9 ? "" : "0") +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();
      axios
        .get("/v1/saveCbreaiSetting", {
          params: {
            email: localStorage.getItem("email"),
            notification_date: dd,
          },
        })
        .then((response) => {
          console.log(response.data);
          window.location.href = "/#/notification_list";
        })
        .catch((error) => console.log(error));
    },
    showintro() {
      if (router.currentRoute.value.path == "/") {
        var introJS = require("intro.js");
        introJS()
          .setOptions({
            steps: [
              {
                element: document.querySelector(".first"),
                intro: this.lan["help_intro_content1"],
              },
              {
                element: document.querySelector(".two"),
                intro: this.lan["help_intro_content2"],
              },
              {
                element: document.querySelector(".three"),
                intro: this.lan["help_intro_content3"],
              },
              {
                element: document.querySelector(".four"),
                intro: this.lan["help_intro_content4"],
              },
              {
                element: document.querySelector(".five"),
                intro: this.lan["help_intro_content5"],
              },
              {
                element: document.querySelector(".sex"),
                intro: this.lan["help_intro_content6"],
              },
              {
                element: document.querySelector(".seven"),
                intro: this.lan["help_intro_content7"],
              },
            ],
          })
          .start();
      } else if (router.currentRoute.value.path == "/market_info") {
        this.isPopup = 1;
        this.current_description = this.lan.help_market_data;
      } else {
        this.isPopup = 1;
        this.current_description = this.lan.help_area_description;
      }
    },

    goto: function (url) {
      this.$router.push(url);
    },
    logout: function () {
      localStorage.removeItem("id_token");
      localStorage.removeItem("groups");
      setTimeout(() => {
        window.location.href = "https://cbredata.com/index.php?to_type=cbreai"; //'https://auth.cbredata.com/login?client_id=1gcmfopbf7qtttffm228f52657&response_type=token&redirect_uri=https://cbredata.com/jp/index.php';
      }, 500);
    },
    change_language: function (lan) {
      localStorage.setItem("cur_lan", lan);
      this.cur_lan = lan;
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.left-side-menu {
  z-index: 9999 !important;
}
#sidebar-menu > ul > li ul {
  padding-left: 10px !important;
}
.research > a {
  font-weight: bolder;
}
.short {
  width: 60px;
}
.irs-hidden-input {
  opacity: 0;
}
.popup-modal {
  display: block;
}
.popup-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 9;
}

.popup {
  background: #fff;
  border-radius: 0.15rem;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.25);
  left: 50% !important;
  max-height: 85vh;
  min-height: 750px;
  width: 850px;
  position: fixed;

  transform: translateX(-50%) !important;
  z-index: 16;
}
.modal-body {
  overflow: auto;
}
.popup iframe {
  width: 100%;
}
.area-btn {
  margin-top: 20px;
  border-top: 2px solid #003f2d;
}
.top-menu {
  float: left;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.top-menu a {
  color: #ffffff;
  text-decoration: none;
}
.top-menu:hover,
.top-menu a:hover {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
}
.top-menu li {
  float: left;
  padding: 0 15px;
  list-style: none;
  line-height: 70px;
  height: 70px;
}
</style>